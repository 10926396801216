// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://api.cjjsup9vs1-mohawkcar1-s1-public.model-t.cc.commerce.ondemand.com/',
  hostURL: "https://devssrbuilderxchange.azurewebsites.net/",
  addressValidationClientId: "eMpower_hybris",
  addressValidationSecret: "Gr3@tJo8!",
  onDemand: {
    baseUrl:
      "https://api.cjjsup9vs1-mohawkcar1-s1-public.model-t.cc.commerce.ondemand.com",
    // apiUser: '/services/v2/pergo/users/',
    apiAuth:
      "/authorizationserver/oauth/token?client_id=sso-client&client_secret=secret&grant_type=sso",
    clientId: "occ_testUser",
    secret: "Mohawk@123",
  },
  mohawkXchangeAppUrl: "https://qa.mohawkxchange.com",
  openAMUrl:"https://ssoproxytst.mohawkind.com/proxy/OIDCintermediate.jsp?RelayState=",
  openAMRedirect:"https://ssoproxytst.mohawkind.com/proxy/XUI/?realm=connector&ForceAuth=true&service=Azure_oidc&goto=",
  // If maintenance (EST) is null or past date, it means no maintenance is scheduled
  maintenance: null,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
